import { PUBLIC_ENVIRONMENT } from '$env/static/public';
import posthog from 'posthog-js';

export const initPosthog = () => {
	const devPosthogId = 'phc_lajN6lUlP8U4PSCo3YqyXIzU42ccUSc91QHamXvKlHY';
	const prodPosthogId = 'phc_p7EDVq5HcrSd2qzxX8rcNj7kbAO2q5k1E1YcQptdA72';
	const posthogId =
		PUBLIC_ENVIRONMENT === 'prod'
			? prodPosthogId
			: PUBLIC_ENVIRONMENT === 'local' || PUBLIC_ENVIRONMENT === 'dev'
				? devPosthogId
				: undefined;

	if (!posthogId) {
		console.error('Posthog not initialized due to invalid environment.');
		return;
	}

	posthog.init(posthogId, {
		api_host: 'https://us.i.posthog.com',
		session_recording: { maskAllInputs: false }
	});
};
